body {
  font-family: "minion-pro-caption", Georgia, "Times New Roman", Times, serif;
  font-size: 13px;
  line-height: 1.5;
  color: #363f48;
  background: #ffffff;
}

a {
  text-decoration: none;
  color: #00b085;
}

a:hover {
  text-decoration: none;
}

a:hover,
a:active,
a:focus,
object,
embed,
input::-moz-focus-inner {
  outline: 0;
}

p {
  margin-bottom: 1.2em;
  font-size: 1.2em;
}

header h1,
header h2,
header h3,
header h4,
header h5,
header h6 {
  font-family: "minion-pro-caption", Georgia, "Times New Roman", Times, serif;
}

h1 {
  font-family: "proxima-nova", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #4b4b4c;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
  line-height: 1.1em;
  font-size: 1.4em;
}

h2 {
  font-size: 23px;
}

h3 {
  font-family: "proxima-nova", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #4b4b4c;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
  line-height: 1.1em;
  font-size: 1.3em;
}

h4 {
  font-size: 19px;
}

h5 {
  font-family: "proxima-nova", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #4b4b4c;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
  line-height: 1.1em;
  font-size: 1.3em;
}

h6 {
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

hr {
  box-sizing: content-box;
  max-width: 10%;
  border-bottom: 4px solid #4b4b4c;
  margin: 2em auto;
}

.clear {
  display: block;
  width: 100%;
  float: left;
  clear: both;
}

section {
  position: relative;
}

#header {
  text-align: center;
  margin-bottom: 0px;
  background: url(https://findbob-production.s3-us-west-2.amazonaws.com/images/ifb/background/cun_background.jpg) center
    center no-repeat;
  background-size: cover;
  overflow: hidden;
  background-color: #151515;
}

#logo {
  text-align: center;
  padding-top: 10px;
}

#logo p {
  margin: 5px 0 5px 0;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
}

#logo h1 {
  color: #fff;
  font-size: 40px;
  margin: 0 0 0 0;
  letter-spacing: -1px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

#slider-container {
  text-align: left;
  margin-top: 120px;
  float: left;
}

#iphone5-slider {
  width: auto;
  background-repeat: no-repeat;
  margin-top: 0px;
  margin-left: 200px;
  overflow: hidden;
  height: 598px;
}

#iphone5-slider.white {
  background-image: url(https://findbob-production.s3-us-west-2.amazonaws.com/images/ifb/devices/iphone5.png);
}

#iphone5-slider .flexslider,
#iphone5-slider .fixed-video-block {
  width: 284px;
  left: 22px;
  top: 97px;
}

#slider-description {
  margin-top: 120px;
}

#slider-description:after {
  clear: both;
  content: '';
  display: table;
}

#slider-description h2 {
  font-size: 42px;
  color: #fff;
  margin-bottom: 15px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.1;
}

#slider-description p {
  font-size: 14px;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  width: 75%;
}

#subscribeform {
  margin-top: 0;
  height: 100px;
  display: block;
}

#subscribeform input {
  height: 40px;
  display: inline-block;
  width: 60%;
  padding: 0 10px;
  margin: 8px 4px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  border: 1px solid #0e4383;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.1);
  color: #d6d3d3;
}

a#button-subscribe {
  color: #fff;
  font-size: 16px;
  padding: 0 10px;
  margin: 8px 4px;
}

#newsletterform {
  margin-top: 0;
  display: block;
  text-align: center;
}

#newsletterform div {
  display: inline-block;
}

#newsletterform input {
  height: 40px;
  display: inline-block;
  width: 300px;
  padding: 0 10px;
  margin: 8px 4px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  border: 1px solid white;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.1);
  color: #d6d3d3;
}

#newsletterform label {
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  font-size: 12px;
}

a#button-newsletter {
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 0;
  width: 99%;
  margin: 0 auto;
}

footer {
  background: #159f70;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
}

.copyright-left {
  line-height: 40px;
  float: left;
}

footer a {
  text-decoration: none;
}

footer a:hover {
  color: #fff !important;
  text-decoration: none;
}

.copyright-right {
  float: right;
  line-height: 40px;
  margin: 0;
}

footer a {
  color: #00b085;
}

.rnr-service-box-center {
  text-align: center;
  padding: 10px;
  margin-bottom: 30px;
}

.rnr-service-box-center .rnr-service-box-icon {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
}

.rnr-service-box-center .rnr-service-box-icon {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
}

.rnr-service-box-center .rnr-service-box-title {
  margin-bottom: 5px;
  text-align: center;
  display: block;
  color: #00b085;
  font-weight: normal;
}

.rnr-service-box-center .rnr-service-box-small-title {
  margin-bottom: 5px;
  text-align: center;
  display: block;
  color: black;
  font-weight: normal;
}

.rnr-service-box-center .rnr-service-box-content p {
  display: block;
  clear: both;
  text-align: center;
}

.rnr-service-box-content p {
  display: block;
  clear: both;
}

.container .rnr-service-box-center {
  border-right: 1px solid #f0f0f0;
}

.container [class*="span"]:last-child .rnr-service-box-center {
  border-right: none;
}

.rnr-service-box-left {
  position: relative;
  margin-bottom: 15px;
}

.rnr-service-box-left .rnr-service-box-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
}

.rnr-service-box-left .rnr-service-box-content {
  margin-left: 70px;
}

.rnr-service-box-left .rnr-service-box-icon img {
  max-width: 60px;
}

.rnr-service-box-left .rnr-service-box-icon span {
  max-width: 60px;
}

.rnr-service-box-left .rnr-service-box-title {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.2;
  color: #00b085;
}

.rnr-service-box-left .rnr-service-box-content p {
  display: block;
  clear: both;
  width: 60%;
}

.rnr-service-box-right {
  position: relative;
  margin-bottom: 15px;
}

.rnr-service-box-right .rnr-service-box-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  text-align: center;
}

.rnr-service-box-right .rnr-service-box-content {
  margin-right: 70px;
  text-align: right;
}

.rnr-service-box-right .rnr-service-box-icon img {
  max-width: 60px;
}

.rnr-service-box-right .rnr-service-box-icon span {
  max-width: 60px;
}

.rnr-service-box-right .rnr-service-box-title {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #00b085;
}

.rnr-service-box-right .rnr-service-box-content p {
  margin-left: 120px;
  display: block;
  clear: both;
  width: 60%;
  text-align: right;
}

.fullwidth {
  background: #f8f8f9;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  position: relative;
}

#fullwidth .container {
  display: table;
}

.fullwidth .text-container {
  margin-top: 30px;
  padding-bottom: 20px;
}

.fullwidth .img-container {
  position: relative;
  bottom: 0;
  right: 0;
  float: right;
  overflow: hidden;
}

.pricing-table {
  margin-top: 60px;
  margin-bottom: 30px;
}

.pricing-table:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.pricing-table > div:first-child .pricing-column-content,
.pricing-table > div:first-child h3 {
  border-left: 1px solid #ececec;
}

.pricing-column {
  float: left;
  text-align: center;
  padding: 0px;
  background-color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  box-shadow: 0px 0px 0px 5px #f5f5f5;
  -webkit-backface-visibility: hidden;
}

.pricing-column .pricing-column-content {
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #e4e4e4;
  padding: 0px 0px 20px 0px;
}

.pricing-column.highlight {
  position: relative;
  z-index: 100;
  margin: -36px -1px 0px -1px;
  background-color: #fff;
  -moz-box-shadow: 0px 0px 0px 5px #f5f5f5;
  -webkit-box-shadow: 0px 0px 0px 5px #f5f5f5;
  -o-box-shadow: 0px 0px 0px 5px #f5f5f5;
  box-shadow: 0px 0px 0px 5px #f5f5f5;
}

.pricing-column.highlight .button {
  margin: 10px 0px 14px 0px;
}

.pricing-column.highlight .pricing-column-content,
.pricing-column.highlight h3 {
  border: none;
}

.pricing-column.highlight h3 .highlight-reason {
  display: block;
  font-size: 12px;
}

.pricing-column.highlight .pricing-column-content {
  border-right: 1px solid #e4e4e4 !important;
  border-left: 1px solid #e4e4e4 !important;
  border-bottom: 1px solid #e4e4e4 !important;
}

.pricing-column.highlight:hover {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  -moz-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  overflow: hidden;
}

.pricing-column:hover {
  -webkit-transform: scale3d(1.02, 1.02, 1.02);
  -moz-transform: scale3d(1.02, 1.02, 1.02);
  transform: scale3d(1.02, 1.02, 1.02);
  z-index: 10;
  overflow: hidden;
}

.pricing-column ul li {
  padding: 10px 25px;
  list-style: none;
  line-height: 18px;
  border-bottom: 1px solid #efefef;
}

.pricing-column ul li:first-child {
  border-top: 1px solid #efefef;
}

.pricing-column ul li:nth-child(2n + 1) {
  background-color: #fbfbfb;
}

.pricing-column.highlight h3 {
  color: #fff !important;
  background-color: #00b085;
  padding: 23px 0px;
  font-size: 34px;
}

.pricing-column h3 {
  background-color: #fff;
  color: #00b085;
  margin-bottom: 0px;
  font-size: 26px;
  line-height: 28px;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #ececec;
  padding: 18px 0px 18px 0px;
  text-transform: uppercase;
  font-weight: 500;
}

.pricing-column h4 {
  margin-left: -22px;
  padding: 20px 30px 6px 30px;
  font-size: 54px;
  line-height: 42px;
  color: #00b085;
  margin-bottom: 0px;
  font-weight: 400;
}

.pricing-column h4 .dollar-sign {
  font-size: 22px;
  line-height: 22px;
  top: -14px;
  right: -6px;
  font-family: 'Open Sans';
  position: relative;
}

.pricing-column .interval {
  display: block;
  color: #999;
  min-height: 38px;
  display: block;
  padding-bottom: 17px;
}

.pricing-column ul {
  margin-left: 0px;
  margin-bottom: 18px;
}

.pricing-table.six > div {
  width: 16.5%;
}

.pricing-table.five > div {
  width: 20%;
}

.pricing-table.four > div {
  width: 25%;
}

.pricing-table.three > div {
  width: 33.2%;
}

.pricing-table.two > div {
  width: 50%;
}

.pricing-table .col {
  padding: 0px 20px;
}

.button {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3), inset 0 -20px 50px rgba(0, 0, 0, 0.1),
    inset 0 1px 1px rgba(255, 255, 255, 0.3);
  background: #00b085;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(rgba(0, 0, 0, 0.2))
  );
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2));
  padding: 0px 15px 4px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #00b085;
  border-bottom: none;
  font-weight: 300;
  line-height: 40px;
  height: 40px;
  transition: background 0.3s linear;
  -moz-transition: background 0.3s linear;
  -webkit-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  display: inline-block;
}

.button:hover {
  background: #00b085;
}

.button-login {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3), inset 0 -20px 50px rgba(0, 0, 0, 0.1),
    inset 0 1px 1px rgba(255, 255, 255, 0.3);
  background: #0e4383;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(rgba(0, 0, 0, 0.2))
  );
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0) rgba(0, 0, 0, 0.2));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2));
  padding: 0px 15px 4px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #0e4383;
  border-bottom: none;
  font-weight: 300;
  line-height: 40px;
  height: 40px;
  transition: background 0.3s linear;
  -moz-transition: background 0.3s linear;
  -webkit-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  display: inline-block;
}

.button-login:hover {
  background: #0e4383;
}

#testimonial-rotator {
  min-height: 120px;
  padding: 35px 0;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

.testimonials,
.testimonials p {
  font-size: 35px;
  font-style: normal;
  font-weight: 300;
  margin-top: 8px;
  letter-spacing: -2px;
  line-height: 36px;
}

.testimonials {
  margin: 0 auto;
  padding: 10px 15px;
  clear: both;
  border-left: 0;
  display: none;
  width: 80%;
}

.client-testimonial {
  margin-top: 22px;
}

.client-testimonial cite {
  display: block;
  font-size: 18px;
  color: #00b085;
  font-weight: normal;
  letter-spacing: 0;
}

.client-image img {
  max-width: 90px;
  border: 4px solid #f0f0f0;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}

.copyright {
  margin-top: 40px;
  padding: 10px 0;
  background-color: #0a4383;
}

ul.footer-menu {
  font-size: 12px;
  padding-left: 5px;
  height: 40px;
}

ul.footer-menu {
  margin: 0;
  float: left;
}

ul.footer-menu > li {
  float: left;
  padding-right: 5px;
  margin-right: 5px;
  font-weight: bold;
  position: relative;
}

.fancy-header {
  margin: 30px 0;
  text-align: center;
}

.fancy-header h2,
h4 {
  color: #00b085;
  font-weight: 300;
}

.about-team {
  background: #f8f8f9;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  position: relative;
  padding: 20px 0;
}

.about-team .team-member {
  float: left;
  width: 21%;
  text-align: center;
  margin: 20px 2% 50px 2%;
}

.about-team .team-member .team-thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: auto;
}

.about-team .team-member .team-desc h5 {
  margin: 10px 0 0px;
  font-size: 14px;
}

.about-team .team-member .team-desc h5 span {
  font-size: 12px;
  font-weight: normal;
  color: #aaa;
}

.about-team .team-member .team-desc a {
  font-weight: normal;
  display: block;
  font-size: 13px;
  margin-top: 0px;
}

.about-team .team-member .team-desc a span {
  color: #363f48;
}

@media (min-width: 768px) {
  #slider-container [class^="col-"]:first-child {
    float: right;
  }

  .animated {
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  @-moz-keyframes fadeInUp {
    0% {
      opacity: 0;
      -moz-transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -moz-transform: translateY(0);
    }
  }
  @-o-keyframes fadeInUp {
    0% {
      opacity: 0;
      -o-transform: translateY(20px);
    }
    100% {
      opacity: 1;
      -o-transform: translateY(0);
    }
  }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }

  @-webkit-keyframes rotateInUpLeft {
    0% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(10deg);
      opacity: 0;
    }
    100% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
    }
  }
  @-moz-keyframes rotateInUpLeft {
    0% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(10deg);
      opacity: 0;
    }
    100% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(0);
      opacity: 1;
    }
  }
  @-o-keyframes rotateInUpLeft {
    0% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(10deg);
      opacity: 0;
    }
    100% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes rotateInUpLeft {
    0% {
      transform-origin: left bottom;
      transform: rotate(10deg);
      opacity: 0;
    }
    100% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
    }
  }

  .rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    -moz-animation-name: rotateInUpLeft;
    -o-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft;
    z-index: 0;
  }

  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(30px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }
  @-moz-keyframes fadeInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(30px);
    }
    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }
  @-o-keyframes fadeInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(30px);
    }
    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    -moz-animation-name: fadeInRight;
    -o-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  .delay2s {
    animation-delay: 2s;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
  }

  .delay3s {
    animation-delay: 3s;
    -moz-animation-delay: 3s;
    -webkit-animation-delay: 3s;
  }
}

.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  padding: 16px 1.5em 12px;
  border: 1px solid #0e4383;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0 5px;
  margin-right: 5px;
  margin-top: 2px;
  border: none;
  color: #fff;
  background: #0e4383;
  border-radius: 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
}

.error {
  padding: 15px;
  margin-top: 10px;
  color: white;
  background: #eb2c06;
  font-size: 16px;
}

@media (max-width: 767px) {
  body {
    padding: 0 !important;
  }

  .container {
    position: relative;
  }

  #slider-container {
    margin-top: 20px;
  }

  #slider-container h2 {
    font-size: 26px;
  }

  #slider-container p {
    width: 100%;
  }

  .container .rnr-service-box-center {
    border: none;
  }

  .fullwidth .img-container {
    float: none;
  }

  .rnr-service-box-left .rnr-service-box-content p {
    width: 100%;
  }

  .client-image {
    display: block;
    width: 100%;
  }

  .client-image img {
    max-width: 50px;
    float: none;
  }

  .client-testimonial {
    margin-top: 0;
  }

  .testimonials,
  .testimonials p {
    font-size: 20px;
    letter-spacing: -1px;
    line-height: 1.2;
    padding: 5px 0;
    text-align: center;
  }

  .client-testimonial cite {
    font-size: 15px;
  }

  .about-team .team-member {
    width: 46%;
  }

  .pricing-table > div {
    width: 100% !important;
    margin: 10px 0 !important;
  }

  .pricing-column:hover {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important;
  }

  #tweet {
    font-size: 14px;
  }

  .tweet_list li {
    line-height: 1.5;
    padding: 62px 0 5px 0;
    width: 100%;
    margin: 0 auto;
  }

  #newsletterform input {
    width: 272px;
  }

  a#button-newsletter {
    width: 300px;
  }

  .copyright {
    text-align: center;
  }

  .copyright-left,
  .copyright-right {
    float: none;
    display: block;
    widt: 100%;
    padding: 5px 0;
    line-height: 1.8;
  }

  ul.footer-menu {
    float: none;
  }

  ul.footer-menu li {
    float: none;
    display: inline-block;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 460px;
  }

  #iphone4S-handslider,
  #iphone5-handslider {
    margin-left: -140px;
    margin-top: 0;
    overflow: hidden;
    height: 414px;
    position: absolute;
    bottom: 0;
    width: 569px;
  }

  #iphone5-handslider {
    height: 450px;
  }

  #slider-description {
    margin-top: 25px;
    margin-bottom: 620px;
    padding: 0 10px;
  }

  #iphone4S-slider,
  #iphone5-slider {
    margin-top: 36px;
    overflow: hidden;
    height: 530px;
    position: absolute;
    bottom: 0;
    margin-left: 38px;
    width: 327px;
  }
}

@media (max-width: 479px) {
  .container {
    width: 300px;
  }

  #iphone4S-handslider,
  #iphone5-handslider {
    margin-left: -250px;
    margin-top: 0;
    overflow: hidden;
    height: 414px;
    position: absolute;
    bottom: 0;
    width: 569px;
  }

  #iphone5-handslider {
    height: 450px;
  }

  #slider-description {
    margin-top: 20px;
    margin-bottom: 570px;
    padding: 0 10px;
  }

  #iphone4S-slider,
  #iphone5-slider {
    margin-top: 36px;
    overflow: hidden;
    height: 530px;
    position: absolute;
    bottom: 0;
    margin-left: -15px;
    width: 327px;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  #iphone4S-handslider,
  #iphone5-handslider {
    margin-left: -220px;
  }

  #iphone4S-slider,
  #iphone5-slider {
    margin-left: 0px;
  }

  #slider-description {
    margin-top: 50px;
  }

  #slider-container h2 {
    font-size: 26px;
  }

  #slider-container p {
    width: 100%;
  }

  .pricing-column h3 {
    font-size: 20px !important;
  }

  .fullwidth .img-container {
    float: none;
  }
}
@media (min-width: 960px) and (max-width: 979px) {
  #iphone4S-handslider,
  #iphone5-handslider {
    margin-left: -220px;
  }

  #iphone4S-slider,
  #iphone5-slider {
    margin-left: 0px;
  }

  #slider-description {
    margin-top: 40px;
  }

  #slider-container h2 {
    font-size: 32px;
  }

  #slider-container p {
    width: 100%;
  }

  .pricing-column h3 {
    font-size: 22px !important;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  #iphone4S-handslider,
  #iphone5-handslider {
    margin-left: -124px;
  }

  #iphone4S-slider,
  #iphone5-slider {
    margin-left: 120px;
  }

  #slider-description {
    margin-top: 80px;
  }
}
@media (min-width: 1200px) {
  #iphone4S-handslider,
  #iphone5-handslider {
    margin-left: 8px;
  }
}
/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/

.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme
*********************************/

.flexslider {
  margin: 0;
  position: relative;
  zoom: 1;
  width: 100%;
  height: auto;
}

.imageslider .flexslider {
  margin: 0 auto;
  position: relative;
  zoom: 1;
  width: 1100px;
  height: auto;
  border: 5px solid white;
  box-shadow: 0px 0px 0;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
  -ms-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
}

.flexslider li {
  margin: 0;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  width: 35px;
  height: 100px;
  margin: -20px 0 0;
  display: block;
  background: url(https://findbob-production.s3-us-west-2.amazonaws.com/images/ifb/bg_direction_nav.png) no-repeat 0 0;
  position: absolute;
  top: 50%;
  cursor: pointer;
  text-indent: -9999px;
  opacity: 0;
  -webkit-transition: all .3s ease;
}

.flex-direction-nav .flex-next {
  background-position: 100% 0;
  right: 50px;
}

.flex-direction-nav .flex-prev {
  left: 50px;
}

.flexslider:hover .flex-next {
  opacity: 0.8;
  right: 5%;
}

.flexslider:hover .flex-prev {
  opacity: 0.8;
  left: 5%;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0.3 !important;
  filter: alpha(opacity=30);
  cursor: default;
}

.flex-control-nav {
  min-width: 90px;
  position: absolute;
  bottom: 25px;
  left: 45.4%;
  padding: 7px 5px 3px 5px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
}

.imageslider .flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 3px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  background: #000;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.flex-control-paging li a:hover {
  background: #222;
}

.flex-control-paging li a.flex-active {
  background: #fff;
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 0;
  }

  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 0;
  }
}

.flex-direction-nav a {
  width: 30px !important;
  height: 30px !important;
}

@media (max-width: 479px) {
  #iphone4S-slider,
  #iphone5-slider {
    position: relative;
    width: 300px;
  }

  #iphone5-slider.white {
    width: 300px;
    background-size: cover;
  }

  #slider-description {
    margin-bottom: 0;
  }

  #iphone5-slider .flexslider {
    left: 20px;
    top: 90px;
    width: 250px;
  }

  #iphone5-slider .flexslider .slides {
    width: 260px;
  }

  .flexslider .slides img {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 767px) and (min-width: 480px) {
  #iphone4S-slider,
  #iphone5-slider {
    position: relative;
  }

  #slider-description {
    margin-bottom: 0;
  }
}
